import React from 'react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import {
  Box,
  Button,
  Flex,
  LightMode,
  Link,
  useColorMode
} from '@chakra-ui/react';
import { PURPLE_BG_PAGES, URLS } from '@lib';
import { openPopupWidget } from 'react-calendly';
import { LayoutContainer } from '../../common/layout-container';
import RadixMenu from './radix-menu';

const { home } = URLS;
const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD_URL || '';

// Ensure the logo and CTAs have same width, so that `MegaMenu` is centred.
const LOGO_AND_CTAS_WIDTH = '400px';
export const HEADER_HEIGHT = '80px';

export function Header(): JSX.Element | null {
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const { colorMode } = useColorMode();

  const { pathname } = useRouter();

  // Check if the page is a 404 page (no data available and not in fallback state)

  const hasPurpleBg = PURPLE_BG_PAGES.includes(pathname);
  const errorLightMode = colorMode === 'light' && pathname === '/_error';
  const logo = hasPurpleBg
    ? '/images/clerk-logo-dark.svg'
    : '/images/clerk-logo.svg';
  const lightErrorLogo =
    hasPurpleBg && errorLightMode && !hasScrolled
      ? '/images/clerk-logo.svg'
      : '/images/clerk-logo-dark.svg';

  const bg =
    hasScrolled && hasPurpleBg
      ? '#101347'
      : hasPurpleBg && !hasScrolled
      ? 'transparent'
      : !hasScrolled
      ? 'transparent'
      : 'gray.25';

  const textColor = hasPurpleBg ? 'white' : 'gray.700';
  const lightErrorTextColor = hasScrolled ? 'white' : 'gray.700';

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScroll = () => {
        const scrollY = window.scrollY;

        if (scrollY > 50 && !hasScrolled) {
          setHasScrolled(true);
        } else if (scrollY <= 50 && hasScrolled) {
          setHasScrolled(false);
        }
      };
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [hasScrolled]);

  return (
    <LayoutContainer
      verticalPadding="none"
      pos="fixed"
      bg={bg}
      top={0}
      w="full"
      borderBottom={hasScrolled ? '1px solid' : 'none'}
      borderColor="gray.200"
      shadow={hasScrolled ? 'base' : 'none'}
      transition="all 0.2s"
      zIndex="sticky"
    >
      <Flex
        as="header"
        justify="space-between"
        align="center"
        gap={6}
        h={HEADER_HEIGHT}
      >
        <Box flexBasis={LOGO_AND_CTAS_WIDTH}>
          <Link href={home}>
            <span className="sr-only">Clerk</span>
            <div className="image-wrap">
              {pathname.includes('/blog') && hasPurpleBg ? (
                <Image
                  src={'/images/blog-dark.svg'}
                  height={98}
                  width={160}
                  alt="Blog"
                  priority
                />
              ) : pathname.includes('/blog') ? (
                <Image
                  src={'/images/blog.svg'}
                  height={98}
                  width={160}
                  alt="Blog"
                  priority
                />
              ) : (
                <Image
                  src={errorLightMode ? lightErrorLogo : logo}
                  height={24}
                  width={77}
                  alt="Clerk logo"
                  priority
                />
              )}
            </div>
          </Link>
        </Box>

        <Flex
          position="relative"
          display="flex"
          width="full"
          flexGrow={1} // Allow the RadixMenu to take up available space
          zIndex={1}
        >
          <RadixMenu
            textColor={errorLightMode ? lightErrorTextColor : textColor}
          />
        </Flex>

        <Box flexBasis={LOGO_AND_CTAS_WIDTH}>
          <Flex position={'relative'} justify="flex-end" gap={5} zIndex={100}>
            <SignedOut>
              {hasScrolled ? (
                <Button
                  variant="ghost"
                  color={errorLightMode ? lightErrorTextColor : textColor}
                  onClick={() => {
                    openPopupWidget({
                      url: URLS.calendlyUrl
                    });
                  }}
                >
                  Book a demo
                </Button>
              ) : (
                <Link href={DASHBOARD_URL + '/sign-in'}>
                  <Button
                    variant="ghost"
                    color={errorLightMode ? lightErrorTextColor : textColor}
                    _hover={{}}
                  >
                    Sign in
                  </Button>
                </Link>
              )}

              <Link href={DASHBOARD_URL + '/sign-up'}>
                <Button variant="white">Sign up</Button>
              </Link>
            </SignedOut>

            <SignedIn>
              <LightMode>
                <Link href={DASHBOARD_URL}>
                  <Button>Dashboard</Button>
                </Link>
              </LightMode>
            </SignedIn>
          </Flex>
        </Box>
      </Flex>
    </LayoutContainer>
  );
}
